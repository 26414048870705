import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public constructor(private matSnackbar: MatSnackBar) {}

  public info(message: string, action?: string): MatSnackBarRef<TextOnlySnackBar> {
    const isMobile: boolean = window.matchMedia('only screen and (max-width: 760px)').matches;
    return this.matSnackbar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: isMobile ? 'bottom' : 'top',
      duration: 2000,
      panelClass: ['mat-app-background'],
    });
  }

  public accessDenied() {
    this.info('Access denied', 'OK');
  }

  public errorOccurred(): void {
    this.info('Error occurred', 'OK');
  }
}
